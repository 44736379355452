<template>
  <div>
    <h2 class="text-h6 mb-5">{{ title }}</h2>
    <div class="pb-5">
      <v-expansion-panels tile accordion v-model="openItem" class="mb-5">
        <template v-for="group in groups">
          <v-expansion-panel :key="group.id+'header'"  v-if="items.filter(i => i.group.id == group.id).length" readonly >
            <v-expansion-panel-header class="px-2 py-0 text-caption" color="blue-grey lighten-5">
              <template v-slot:actions class="order-1">
                <div class="trainings__icon"></div>
              </template>
              <v-row align="center" class="my-0 order-2">
                <v-col cols="12" sm="auto" class="py-2 flex-grow-1">
                  <div class="ml-2 text-subtitle-2">{{ group.name || 'Без группы' }}</div>
                </v-col>
                <v-divider vertical></v-divider> 
                <v-col cols="12" lg="1" sm="2" class="py-2 d-none d-sm-block">
                  Дата создания
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="12" sm="auto" class="d-none d-sm-block">
                  <div class="trainings__actions-width"></div>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-expansion-panel>
          <div :key="group.id+'content'" class="w-100">
            <v-expansion-panel 
              v-for="(item,index) in items.filter(i => i.group.id == group.id)"
              :key="item.id"
              active-class="grey lighten-5"
              class="trainings-item"
            >
              <v-expansion-panel-header class="px-2 py-0">
                <template v-slot:actions class="order-1">
                  <v-icon color="teal" >
                    mdi-menu-down
                  </v-icon>
                </template>
                <v-row align="center" class="my-0 order-2">
                  <v-col cols="12" sm="auto" class="flex-grow-1">
                    <div class="ml-2">{{ item.name }}</div>
                  </v-col>
                  <v-divider vertical class="d-none d-sm-block"></v-divider> 
                  <v-col cols="auto" lg="1" sm="2" class="ml-2 ml-sm-0">
                    <div v-if="item.created_at">
                      {{ new Date(item.created_at) | moment("DD.MM.YYYY HH:mm") }}
                    </div>
                  </v-col>
                  <v-divider vertical class="d-none d-sm-block"></v-divider>
                  <v-col cols="auto" class="ml-auto">
                    <div class="d-flex trainings__actions-width">
                      <v-tooltip top v-if="canEdit(item) && !isArchive">
                        <template #[`activator`]="{ on, attrs }">
                          <v-btn @click.stop :to="`/templates/${item.id}/edit`" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        Редактировать
                      </v-tooltip>
                      <v-tooltip top>
                        <template #[`activator`]="{ on, attrs }">
                          <v-btn @click.stop="$parent.cloneId = item.id" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
                          </v-btn>
                        </template>
                        Клоноровать
                      </v-tooltip>
                      <v-tooltip top v-if="canEdit(item) && !isArchive">
                        <template #[`activator`]="{ on, attrs }">
                          <v-btn @click.stop="$parent.remove(item.id)" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-package-down</v-icon>
                          </v-btn>
                        </template>
                        Отправить в архив
                      </v-tooltip>
                      <v-tooltip top v-if="canEdit(item) && isArchive">
                        <template #[`activator`]="{ on, attrs }">
                          <v-btn @click.stop="$parent.restore(item.id)" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-package-up</v-icon>
                          </v-btn>
                        </template>
                        Восстановить
                      </v-tooltip>
                      <v-tooltip top  v-if="canEdit(item) && isArchive">
                        <template #[`activator`]="{ on, attrs }">
                          <v-btn @click.stop="deleteId = item.id; openRemoveDialog = true" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </template>
                        Удалить
                      </v-tooltip>                      
                    </div>
                  </v-col>
                </v-row>          
              </v-expansion-panel-header>
              <v-expansion-panel-content class="sr-ex-panel-content">
                <template-show v-if="index+1 == openItem" :template="item"></template-show>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </div>
        </template>
      </v-expansion-panels>
      <v-dialog v-model="openRemoveDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="mb-7">
            <span class="headline">Удалить шаблон</span>
          </v-card-title>
          <v-card-text>
            Вы действительно хотите удалить шаблон?
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              dark
              color="blue-grey lighten-2"
              @click="closeRemoveDialog"
            >
              Отмена
            </v-btn>
            <v-btn
              dark
              color="red darken-2"
              @click="remove"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-divider class="mb-5"></v-divider>
  </div>
</template>

<script>
 
import TemplateShow from './TemplateShow'
export default {
  components: {
    TemplateShow,
  },
  props: {
    title: null,
    items: {
      default: []
    },
    groups: {
      default: []
    },
    isEditable: {
      default: true
    },
    isArchive: {
      default: false
    }
  },
  data: () => ({
    loading: true,
    openItem: null,
    deleteId: null,
    openRemoveDialog: false,
  }),
  computed: {
    expert() {
      return this.$store.state.expert;
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    }
  },
  methods: {
    closeRemoveDialog() {
      this.deleteId = null;
      this.openRemoveDialog = false;
    },
    remove() {
      let url = `/templates/${this.deleteId}?force=1`
       this.$api.delete(url)
        .then(() => {          
          this.$store.dispatch('addMessage', 'Шаблон удален')
          this.$parent.getTemplates();
          this.closeRemoveDialog();
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    canEdit(template) {
      if (!this.isEditable) {
        return false;
      }
      console.log(this.isSuperAdmin);
      return this.isSuperAdmin || (template.user.id == this.$store.state.auth.user.id);
    }
  }
}
</script>
