<template>
  <v-simple-table dense>
    <template v-slot:default >
      <thead class="blue-grey lighten-5">
        <tr class="text-caption grey--text ">
          <th class="text-left font-weight-regular">
            Группа тренингов
          </th>
          <th class="text-left font-weight-regular">
            Тренинг
          </th>
          <th class="text-left font-weight-regular">
            Уровень План
          </th>
          <th class="text-left font-weight-regular text-width">
            Тренниг. Успех. Сообщение
          </th>
          <th class="text-left font-weight-regular text-width">
            Тренниг. Неуспех. Сообщение
          </th>
        </tr>
      </thead>
      <tbody>
        <template  v-for="item in items">
          <tr :key="item.id" > 
            <td>{{ getTrainingGroupName(item.training_id) }}</td>
            <td>{{ getTrainingName(item.training_id) }}</td>
            <td>{{ item.level_plan }}</td>
            <td class="text-width">{{ item.message_success }}</td>
            <td class="text-width">{{ item.message_fail }}</td>
          </tr>
        </template>
        
      </tbody>
    </template>
  </v-simple-table>  
</template>

<script>
export default {
  props: {
    items: {
      default: () => { return []},
      type: Array
    }
  },
  data: () => ({
    opened: null,
  }),
  computed: {

  },
  methods: {
    getTrainingName(id) {
      let name = '';
      let training = this.$store.state.trainings.find(t => t.id == id);
      if (training) {
        name = training.name;
      }
      return name;
    },
    getTrainingGroupName(id) {
      let name = '';
      let training = this.$store.state.trainings.find(t => t.id == id);
      if (training) {
        name = training.group.name;
      }
      return name;
    }
  }
}
</script>
<style lang="scss">
  .trainings {
    &__result-count {
      min-width: 24px !important;
    }
    
  }
</style>
