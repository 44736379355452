<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="mb-7">
          <span class="headline">Клонировать шаблон</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              :rules="[rules.requiredName]"
              v-model="name"
              label="Введите название"
              required
              color="teal"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="blue-grey lighten-2"
            @click="closeDialog"
          >
            Отмена
          </v-btn>
          <v-btn
            dark
            color="teal"
            @click="cloneTemplate"
          >
            Клонировать
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
 
export default {
    props: {
        cloneId: null,
    },
    data: () => ({
      dialog: false,
      name: '',
      valid: false,
      rules: {
        requiredName: value => !!value || 'Введите название шаблона.',
      },
    }),
    watch: {
      cloneId() {
        if (this.cloneId) {
          this.dialog = true
        }
      }
    },
    methods: {
      closeDialog() {
        this.name = '';
        this.dialog = false;
        this.$parent.cloneId = null;
      },
      cloneTemplate() {
        if (this.$refs.form.validate()) {
          let url = `/templates/${this.cloneId}/clone`
           this.$api.put(url,{name: this.name})
            .then(() => {
              this.$store.dispatch('addMessage', 'Шаблон клонирован')
              this.$store.dispatch('updateItemsList', true)
            })
            .catch(error => {     
              console.log(error);      
                this.$store.dispatch('addRequestError', error)
            })
          this.closeDialog();
        }
      }
    }
}
</script>

<style>

</style>