<template>
  <div>
    <v-row align="center" class="mb-10">
      <v-col cols="12" sm="2">
        <v-btn v-if="!isArchive" dark to="/syllabi/create" color="teal">Новая программа</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4">
          <div class="d-flex" v-if="$store.getters.isAdmin">
            <div class="mr-2 c-checkbox" >
              <v-checkbox
                v-model="allSpecialists"
                color="teal"
                label="Все"
              ></v-checkbox>
            </div>
            
            <change-expert :isDisabled="allSpecialists" />
          </div>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
            v-model="sort"
            :items="sortingItems"
            item-value="value"
            label="Сортировать по"
            dense
            hide-details
            color="teal"
            class="flex-grow-0 pt-2"
            item-color="teal lighten-1">
            <template  #[`selection`]="{ item }">
              <div class="d-flex w-100">
                {{ item.text }}
                <v-spacer/>
                <v-icon class="ml-2">{{ item.icon }}</v-icon>
              </div>
            </template>
            <template  #[`item`]="{ item }">
              <div class="d-flex w-100">
                {{ item.text }}
                <v-spacer/>
                <v-icon class="ml-2">{{ item.icon }}</v-icon>
              </div>
            </template>
        </v-select>
      </v-col>
    </v-row>
    <v-expansion-panels tile accordion v-model="openItem">
      <v-expansion-panel readonly>
        <v-expansion-panel-header class="px-2 py-0 text-caption sr-ex-panel-header sillab-list" color="blue-grey lighten-5">
          <template v-slot:actions class="order-1">
            <div class="trainings__icon"></div>
          </template>
          <v-row align="center" class="my-0 order-2">
            <v-col cols="12" sm="3" class="py-sm-2 pt-2 pb-1">
              <div class="ml-sm-2">Пользователь</div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" lg="3" sm="2" class="py-sm-2 py-0 ">
              Название
            </v-col>
            <v-spacer/>
            <v-divider vertical></v-divider> 
             <v-col cols="4" lg="1" sm="2" class="py-sm-2 pb-2 pt-0">
              Дата создания
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="4" lg="1" sm="2" class="py-sm-2 pb-2 pt-0">
              Дата последней тренировки
            </v-col>
            <v-divider vertical></v-divider>
             <v-col cols="auto" sm="auto" class="py-sm-2 pb-2 pt-0">
              Прогресс
            </v-col>
            <v-divider vertical></v-divider>
            
            <v-col cols="12" sm="auto" class="d-none d-sm-block">
              <div class="trainings__actions-width"></div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel 
        v-for="(syllabus,index) in syllabi"
        :key="syllabus.id"
        class="sr-item sillab-list__item"
        active-class="grey lighten-5">
        <v-expansion-panel-header class="px-sm-2 px-0 py-0" @click="openIndex = index">
          <template v-slot:actions class="order-1">
            <v-icon color="teal" >
              mdi-menu-down
            </v-icon>
          </template>
          <v-row align="center" class="my-0 order-2">
            <v-col cols="12"  sm="3">
              <div class="ml-2">{{ syllabus.client.name }}</div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" lg="3" sm="2">
              {{ syllabus.name }}
            </v-col>
            <v-spacer/>
            <v-divider vertical></v-divider> 
            <v-col cols="4" lg="1" sm="2">
              <div v-if="syllabus.created">
                {{ new Date(syllabus.created) | moment("DD.MM.YYYY HH:mm") }}
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="4" lg="1" sm="2">
              <div v-if="syllabus.tasks.last_training">
              {{ new Date(syllabus.tasks.last_training) | moment("DD.MM.YYYY HH:mm") }}
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="auto" sm="auto">
              <v-progress-circular
                :rotate="-90"
                :size="40"
                :width="5"
                :value="syllabus.days_completed_percent"
                color="teal"
                class="mx-2"
              >
              {{ syllabus.days_completed_percent }}
              </v-progress-circular>
            </v-col>
            <v-divider vertical></v-divider>
            
            <v-col cols="12" sm="auto" class="ml-auto">
            <div class="d-flex trainings__actions-width ml-auto">
              <v-tooltip top v-if="!isArchive">
                <template #[`activator`]="{ on, attrs }">
                  <v-btn @click.stop="edit(syllabus.id)" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                Редактировать
              </v-tooltip>
              <!-- <v-tooltip top>
                <template #[`activator`]="{ on, attrs }">
                  <v-btn @click.stop="clone(syllabus.id)" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil-box-multiple-outline</v-icon>
                  </v-btn>
                </template>
                Клоноровать
              </v-tooltip> -->
              <v-tooltip top v-if="isArchive">
                <template #[`activator`]="{ on, attrs }">
                  <v-btn @click.stop="restore(syllabus.id)" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-package-up</v-icon>
                  </v-btn>
                </template>
                Восстановить
              </v-tooltip>
              <v-tooltip top v-else>
                <template #[`activator`]="{ on, attrs }">
                  <v-btn @click.stop="remove(syllabus.id)" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-package-down</v-icon>
                  </v-btn>
                </template>
                Отправить в архив
              </v-tooltip>
            </div>
            </v-col>
          </v-row>
        
        </v-expansion-panel-header>
        <v-expansion-panel-content class="sr-ex-panel-content">
          <syllabus-show v-if="index == openIndex" :syllabus="syllabus"></syllabus-show>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
 
import SyllabusShow from './SyllabusShow'
export default {
  components: {
    SyllabusShow
  },
  props: {
    isArchive: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    syllabi: () => { return [] },
    loading: true,
    openItem: null,
    openIndex: null,
    allSpecialists: true,
    sort: '-created',
    sortingItems: [
     { value: 'created', text:'Дата создания', icon: 'mdi-sort-ascending'  },
     { value: '-created', text:'Дата создания', icon: 'mdi-sort-descending'  },     
     { value: 'training', text:'Дата последней тренировки', icon: 'mdi-sort-ascending'  },
     { value: '-training', text:'Дата последней тренировки', icon: 'mdi-sort-descending'  },
     { value: 'name', text:'Название программы', icon: 'mdi-sort-ascending'  },
     { value: '-name', text:'Название программы', icon: 'mdi-sort-descending'  },
     { value: 'username', text:'Имя пользователя', icon: 'mdi-sort-ascending'  },
     { value: '-username', text:'Имя пользователя', icon: 'mdi-sort-descending'  },
    ],
  }),
  created() {
    this.getSyllabi();
  },
  watch: {
    sort() {
      this.sortItems();
    },
    expert() {
      this.getSyllabi();
      this.openItem = null;
      this.openIndex = null;
    }
  },
  computed: {
    expert() {
      return this.allSpecialists ? null : this.$store.state.expert;
    },
  },
  methods: {
    sortItems() {
      switch (this.sort) {
        case '-created':
          this.syllabi.sort((a,b) => (a.created < b.created) ? 1 : ((b.created < a.created) ? -1 : 0));
          break;
        case 'created':
          this.syllabi.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0));
          break;
        case '-training':
          this.syllabi.sort((a,b) => (a.tasks.last_training < b.tasks.last_training) ? 1 : ((b.tasks.last_training < a.tasks.last_training) ? -1 : 0));
          break;
        case 'training':
          this.syllabi.sort((a,b) => (a.tasks.last_training > b.tasks.last_training) ? 1 : ((b.tasks.last_training > a.tasks.last_training) ? -1 : 0));
          break;
        case '-name':
          this.syllabi.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0));
          break;
        case 'name':
          this.syllabi.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          break;
        case '-username':
          this.syllabi.sort((a,b) => (a.client.name < b.client.name) ? 1 : ((b.client.name < a.client.name) ? -1 : 0));
          break;
        case 'username':
          this.syllabi.sort((a,b) => (a.client.name > b.client.name) ? 1 : ((b.client.name > a.client.name) ? -1 : 0));
          break;
      
        default:
          break;
      }
    },
    getSyllabi() {
      this.loading = true
      let url = `/syllabi`;
      this.$api.get(url,{
        params: {
          expert_id: this.expert,
          is_archive: this.isArchive,
        }
      })
      .then(resp => {
        this.loading = false;
        this.syllabi = resp.data._embedded.syllabi;
        this.sortItems();
      })
      .catch(error => {           
          this.$store.dispatch('addRequestError', error)
      })
    },
    remove(id) {
      this.$emit("remove",id);
    },
    restore(id) {
      this.$emit("restore",id);
    },
    edit(id) {
      this.$router.push(`/syllabi/${id}/edit`);
    },
    clone(id) {
      this.$emit("clone",id);
    }
  }
}
</script>

<style lang="scss">
  .c-checkbox {
    display: flex;
    align-items: flex-end;
  }
  .c-checkbox .v-input--selection-controls{
    margin-top: 0;
  }
  .c-checkbox .v-messages {
    display: none;
  } 
  .c-checkbox .v-input__slot {
    margin-bottom: 0;
  } 
</style>