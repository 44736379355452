<template>
  <div class="trainings">
    <page-title :title="'Тренинги'"></page-title>
     <v-tabs
        v-model="tab"
        class="custom__tabs"
        hide-slider
        show-arrows
        background-color="transparent"
      >
      <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
        <v-icon left>mdi-format-list-bulleted</v-icon>
        Программы
      </v-tab >
      <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
        <v-icon left>mdi-school</v-icon>
        Шаблоны          
      </v-tab>
      <v-tab v-if="$store.getters.isAdmin" active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
        <v-icon left>mdi-view-list</v-icon>
        Группы шаблонов          
      </v-tab>
    </v-tabs>
    <v-card class="pa-3 rounded-0 flat custom__card">
      <v-tabs-items touchless v-model="tab">
        <v-tab-item class="pt-2">
          <syllabi-tab v-if="tab === 0"></syllabi-tab>
        </v-tab-item>
        <v-tab-item class="pt-2">
          <template-list v-if="tab === 1"></template-list>
          <v-btn class="mt-5" @click="showTemplateArchive = !showTemplateArchive" color="blue-grey lighten-3">Архив</v-btn>
          <template-list v-if="tab === 1 && showTemplateArchive" :isArchive="true" class="mt-5"></template-list>
        </v-tab-item>
        <v-tab-item class="pt-2">
          <template-group-list></template-group-list>
        </v-tab-item>
      </v-tabs-items>
        
    </v-card>
  </div>
</template>
<script>
import PageTitle from '../../components/main/PageTitle'
import SyllabiTab from '../../components/syllabi/SyllabiTab'
import TemplateList from '../../components/template/TemplateList'
import TemplateGroupList from '../../components/template_group/TemplateGroupList'
export default {
  components: { 
    PageTitle,
    SyllabiTab,
    TemplateList,
    TemplateGroupList
  },
  data: () => ({
    tab: 0,
    showArchive: false,
    showTemplateArchive: false,
    
    TemplatesGroup: [
     { name: 'Группа 1', dateCreate:'17.02.2021 13:32'},
     { name: 'Группа 2', dateCreate:'17.02.2021 13:32'},
     { name: 'Группа 3', dateCreate:'17.02.2021 13:32'},
     { name: 'Группа 4', dateCreate:'17.02.2021 13:32'},
    ]
  }),
  computed: {
    
  },
  methods: {
  }
}
</script>
