<template>
  <div>
    <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>
    <div v-else>
      <v-row align="center" class="mb-2 justify-space-between">
        <v-col cols="12" sm="2">
          <v-btn @click="createDialog" dark color="teal">Добавить группу</v-btn>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead class="blue-grey lighten-5">
            <tr class="text-caption grey--text ">
              <th class="text-left font-weight-regular">
                Название группы
              </th>
              <th class="text-left font-weight-regular">
                Дата создания
              </th>
              <th ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in groups" :key="item.id">
              <td>{{ item.name }}</td>
              <td class="date__width">{{ new Date(item.created_at) | moment("DD.MM.YYYY HH:mm") }}</td>
              <td class="two-actions__width">
                <div class="d-flex">
                  <v-tooltip top>
                    <template #[`activator`]="{ on, attrs }">
                      <v-btn @click="editDialog(item)" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    Редактировать
                  </v-tooltip>
                  <v-tooltip top>
                    <template #[`activator`]="{ on, attrs }">
                      <v-btn @click="deleteId = item.id; openRemoveDialog = true" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </template>
                    Удалить
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog v-model="openDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="mb-7">
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                :rules="[rules.requiredName]"
                v-model="name"
                label="Введите название"
                required
                color="teal"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              dark
              color="blue-grey lighten-2"
              @click="closeDialog"
            >
              Отмена
            </v-btn>
            <v-btn
              dark
              color="teal"
              @click="saveGroup"
            >
              {{ editId ? 'Обновить' : 'Добавить' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openRemoveDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="mb-7">
            <span class="headline">Удалить группу</span>
          </v-card-title>
          <v-card-text>
            Вы действительно хотите удалить группу?
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              dark
              color="blue-grey lighten-2"
              @click="closeRemoveDialog"
            >
              Отмена
            </v-btn>
            <v-btn
              dark
              color="red darken-2"
              @click="remove"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>    
  </div>
</template>

<script>
 
export default {
  data: () => ({
    openDialog: false,
    openRemoveDialog: false,
    loading: true,
    groups: [],
    dialogTitle: 'Добавить группу',
    valid: true,
    rules: {
      requiredName: value => !!value || 'Введите название шаблона.',
    },
    name: '',
    editId: null,
    deleteId: null
  }),
  created() {
    this.getGroups();
  },
  methods: {
    getGroups() {
      this.loading = true
      let url = '/templates-groups';
       this.$api.get(url)
        .then(resp => {
          this.loading = false;
          this.groups = resp.data._embedded.groups;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    createDialog() {
      this.editId = null;
      this.dialogTitle = 'Добавить группу';
      this.name = '';
      this.openDialog = true;
    },
    editDialog(item) {
      this.editId = item.id;
      this.dialogTitle = 'Изменить группу';
      this.name = item.name;
      this.openDialog = true;
    },
    closeDialog() {
      this.name = '';
      this.editId = null;
      this.openDialog = false;
    },
    closeRemoveDialog() {
      this.deleteId = null;
      this.openRemoveDialog = false;
    },
    saveGroup() {
      if (this.$refs.form.validate()) {
        if (this.editId) {
          this.patch()
        } else {
          this.store()
        }
      }
    },
    store() {
      if (this.$refs.form.validate()) {
        let url = '/templates-groups'
         this.$api.post(url,{name: this.name})
          .then(() => {
            this.$store.dispatch('addMessage', 'Группа добавлена')
            this.getGroups();
            this.closeDialog();
          })
          .catch(error => {     
               
            this.$store.dispatch('addRequestError', error)
          })
      }
    },
    patch() {
      if (this.$refs.form.validate()) {
        let url = `/templates-groups/${this.editId}`
         this.$api.patch(url,{name: this.name})
          .then(() => {
            this.$store.dispatch('addMessage', 'Изменения сохранены');
            this.getGroups();
            this.closeDialog();
          })
          .catch(error => {           
              this.$store.dispatch('addRequestError', error)
          })
      }
    },
    remove() {
      let url = `/templates-groups/${this.deleteId}`
       this.$api.delete(url)
        .then(() => {          
          this.$store.dispatch('addMessage', 'Группа удалена')
          this.getGroups();
          this.closeRemoveDialog();
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    }
  }
}
</script>

<style>

</style>