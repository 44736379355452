<template>
  <div>
    <v-expansion-panels tile accordion>
      <v-expansion-panel
        v-for="(day,index) in template.days"
        :key="index"
        active-class="pb-5"
      >
        <v-expansion-panel-header class="px-2 py-0">
          <template v-slot:actions class="order-1">
            <v-icon color="teal" >
              mdi-menu-down
            </v-icon>
          </template>
          <div class="order-2"><div class="ml-2">День {{ index + 1 }}</div></div>
        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <template-task :items="day.tasks"></template-task>
          <v-divider class="my-5"></v-divider>
          <div class="d-flex align-center">
            <span class="grey--text text-caption mr-2">День. Успех. Сообщение</span>
            {{ day.message_success }}
          </div>
          <div class="d-flex align-center">
            <span class="grey--text text-caption mr-2">День. Неуспех. Сообщение</span>
            {{ day.message_fail }}
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>
import TemplateTask from './TemplateTask'
export default {
  components: {
    TemplateTask,
  },
  props: {
    template: null,
  },
  created() {},

  methods: {
    
  }
}
</script>
<style lang="scss">
  
</style>