<template>
  <div>
    <syllabi-list @remove="remove" ref="mainlist"></syllabi-list>
    <v-btn @click="showArchive = !showArchive" class="mt-5" color="blue-grey lighten-3">Архив</v-btn>
    <syllabi-list @restore="restore" ref="archivelist" v-if="showArchive" :isArchive="true" class="mt-5"></syllabi-list>
  </div>  
  
</template>

<script>
import SyllabiList from './SyllabiList'
 
export default {
  components: {
    SyllabiList,
  },
  data: () => ({
    showArchive: false,
  }),
  methods: {
    remove(id) {
      let url = `/syllabi/${id}`
       this.$api.delete(url)
        .then(() => {          
          this.$store.dispatch('addMessage', 'Программа отправлена в архив')
          this.$refs.mainlist.getSyllabi();
          if (this.showArchive) {
            this.$refs.archivelist.getSyllabi();
          }
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    restore(id) {
      let url = `/syllabi/${id}/restore`
       this.$api.patch(url)
        .then(() => {          
          this.$store.dispatch('addMessage', 'Программа восстановлена')
          this.$refs.mainlist.getSyllabi();
          this.$refs.archivelist.getSyllabi();
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    }
  }
}
</script>

<style>

</style>