<template>
  <div>
    <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>
    <template v-else>
      <v-row align="center" class="mb-2">
        <v-col cols="12" sm="2">
          <v-btn v-if="!isArchive"  dark to="/templates/create" color="teal">Создать шаблон</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <change-expert v-if="$store.getters.isAdmin" />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="sort"
            :items="sortingItems"
            item-value="value"
            label="Сортировать по"
            dense
            class="flex-grow-0 pt-2"            
            hide-details
            color="teal"
            item-color="teal lighten-1">
            <template  #[`selection`]="{ item }">
              <div class="d-flex w-100">
                {{ item.text }}
                <v-spacer/>
                <v-icon class="ml-2">{{ item.icon }}</v-icon>
              </div>
            </template>
            <template  #[`item`]="{ item }">
              <div class="d-flex w-100">
                {{ item.text }}
                <v-spacer/>
                <v-icon class="ml-2">{{ item.icon }}</v-icon>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <div v-if="commonTemplates.length">
        <template-list-group 
          :title="'Общие шаблоны'" 
          :isEditable="isAdmin" 
          :isArchive="isArchive" 
          :items="commonTemplates" 
          :groups="groups"/>
      </div>
      <div v-if="privateTemplates.length">
        <template-list-group 
          :title="'Личные шаблоны'" 
          :isEditable="true" 
          :isArchive="isArchive" 
          :items="privateTemplates" 
          :groups="groups"/>
      </div>
      <template-clone :cloneId="cloneId"></template-clone>
    </template>
  </div>
</template>

<script>
 
import TemplateListGroup from './TemplateListGroup'
import TemplateClone from './TemplateClone'

export default {
  components: {
    TemplateListGroup,
    TemplateClone
  },
  props: {
    isArchive: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    loading: true,
    openItem: null,
    templates: [],
    cloneId: null,
    sortingItems: [
     { value: 'created', text:'Дата создания', icon: 'mdi-sort-ascending'  },
     { value: '-created', text:'Дата создания', icon: 'mdi-sort-descending'  },
     { value: 'name', text:'Название', icon: 'mdi-sort-ascending'  },
     { value: '-name', text:'Название', icon: 'mdi-sort-descending'  },
    ],
    sort: '-created',
  }),
  created() {
    this.getTemplates();
  },
  watch: {
    sort() {
      this.sortItems();
    },
    expertId() {
      this.getTemplates()
    },
    isUpdate() {
      if (this.isUpdate) {
        this.getTemplates();
        setTimeout(() => {
          this.$store.dispatch('updateItemsList', false)
        }, 100);       
      }
    }
  },
  computed: {
    isUpdate() {
      return this.$store.state.updateItemsList;
    },
    expertId() {
      return this.$store.state.expert;
    },
    groups() {
      let groups = [];
      this.templates.forEach(t => {
        if (!groups.find(g => g.id == t.group.id)) {
          groups.push(t.group);
        }
      })
      return groups;
    },
    commonTemplates() {
      let templates = this.templates.filter(t => t.user.id != this.expertId)
      return templates;
    },
    privateTemplates() {
      let templates = this.templates.filter(t => t.user.id == this.expertId)
      return templates;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
  },
  methods: {
    sortItems() {
      switch (this.sort) {
        case '-created':
          this.templates.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((b.created_at < a.created_at) ? -1 : 0));
          break;
        case 'created':
          this.templates.sort((a,b) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0));
          break;
        case '-name':
          this.templates.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0));
          break;
        case 'name':
          this.templates.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          break;
      
        default:
          break;
      }
    },
    getTemplates() {
      let url = '/templates';
      this.$api.get(url,
        {
          params: {
              expert_id: this.expertId,
              is_archive: this.isArchive
          }
        })
      .then(resp => {
        this.loading = false;
        this.templates = resp.data._embedded.templates;
        this.sortItems();
      })
      .catch(error => {           
          this.$store.dispatch('addRequestError', error)
      })
    },
    remove(id) {
      let url = `/templates/${id}`
       this.$api.delete(url)
        .then(() => {
          this.templates = this.templates.filter(t => t.id != id);
          this.$store.dispatch('addMessage', 'Шаблон отправлен в архив')
          //this.$store.dispatch('updateItemsList', true)
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    restore(id) {
      let url = `/templates/${id}/restore`
       this.$api.patch(url)
        .then(() => {
          this.templates = this.templates.filter(t => t.id != id);
          this.$store.dispatch('addMessage', 'Шаблон восстановлен')
          this.$store.dispatch('updateItemsList', true)
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    }
  }
}
</script>

<style lang="scss">
  
</style>